.overLay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.homeVideoContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: black; /* set a background color in case the video doesn't load */
}

.homeVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeHeading {
  font-size: 50px;
  letter-spacing: 4px;
}
.headContainer > h6 {
  font-style: italic;
  letter-spacing: 2px;
  color: whitesmoke;
}

.headContainer {
  position: relative;
  z-index: 2;
  top: 10%;
  color: #ffbc03;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayContent {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.cardDiv {
  display: inline-block;
  width: auto;
  background: #ffbc03;
  position: relative;
  top: 13px;
  z-index: 1;
  border-radius: 5px;
  padding: 5px;
  left: 20px;
}
.cardDiv > h5 {
  letter-spacing: 2px;
}
.cardText {
  font-size: 13px !important;
  margin-bottom: 4px !important;
}

.demoBtnContainer {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 10px;
}

.loginBtn {
  background: #ffbc03 !important;
  border: #ffbc03 !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

@media (max-width: 480px) {
  .loginBtn > span:first-of-type {
    font-size: 0.9rem;
  }
  .loginBtn > span:last-of-type {
    font-size: 0.7rem;
  }
}

@media (max-width: 380px) {
  .btnContainer {
    flex-wrap: wrap;
    gap: 7px;
  }
}

@media (max-width: 314px) {
  .overlayContent > div > h1 {
    font-size: clamp(20px, 10vw, 18px);
  }
  .overlayContent > div > h6 {
    font-size: clamp(11px, 4vw, 16px);
  }
}

@media (min-width: 768px) and (max-width: 906px) {
  .cardDiv > h5 {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 768px) {
  .headContainer {
    top: 3%;
    padding-bottom: 12px;
  }
  .headContainer > h6 {
    letter-spacing: 1px;
    font-size: 0.8rem;
  }
  .homeHeading {
    font-size: 40px;
    letter-spacing: 2px;
  }
  .cardDiv {
    padding: 2px;
  }
  .cardDiv > h5 {
    font-size: 1rem;
  }
  .cardText {
    font-size: 12px !important;
    margin-bottom: 2px !important;
  }
  .lastCardCol {
    padding-bottom: 70px !important;
  }
}
