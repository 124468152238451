.impCard {
  width: 90% !important;
  height: 150px !important;
  border-radius: 10px !important;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.tblHead > tr > th {
  background: #2362ba !important;
  color: whitesmoke !important;
  padding: 1em !important;
}
.tblHead > tr > th:first-child {
  border-top-left-radius: 10px !important;
}
.tblHead > tr > th:last-child {
  border-top-right-radius: 10px !important;
}

.tblBody > tr > td {
  padding: 15px !important;
  font-size: 14px;
  text-align: center;
}

.tabContainer {
  padding: 20px;
  background-color: #ede8f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
