.logo {
  height: 55px;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.loginBtn {
  background: #ffbc03 !important;
  border: #ffbc03 !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.navlink {
  text-decoration: none;
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.dropdownNav {
  font-size: 14px !important;
  background-color: whitesmoke !important;
  min-width: 12em !important;
  padding: 7px !important;
  padding-bottom: 0px !important;
  text-decoration: none;
}

.dropdownNav a:hover {
  color: #ffbc03 !important;
}

@media screen and (min-width: 767px) and (max-width: 790px) {
  .loginBtn {
    font-size: 11px !important;
  }
}
