.contactContainer {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 17em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contactCard {
  width: 100%;
  height: 65%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px) saturate(180%);
  -webkit-backdrop-filter: blur(4px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.62);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}
.contactBtn {
  width: 10em;
}
@media (max-width: 425px) {
  .contactContainer {
    height: 12em;
  }
  .contactCard {
    height: 60%;
  }
  .contactBtn {
    width: 8em;
  }
}
@media (max-width: 320px) {
  .contactContainer {
    height: 11em;
  }
  .contactBtn {
    width: auto;
  }
}
