@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,900;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.customTextShadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.subHeading {
  color: #2362ba;
  padding-bottom: 10px;
}
.heading-underline {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  color: black;
}
.heading-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #ffbc03;
}

.bannerImg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
}
.bannerImg > h1 {
  position: relative;
  z-index: 2;
  text-align: center;
}

.imgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

/* Custom CSS for Tabs */
.nav-tabs .nav-link {
  font-size: 18px;
  color: black !important;
}

.nav-tabs .nav-link.active {
  font-size: 20px;
  color: #2362ba !important;
  font-weight: 600 !important;
}

.backBtn {
  background: transparent;
  position: relative;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  outline: none;
  overflow: hidden;
  color: black;
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
}

.backBtn span {
  margin-left: 10px;
}

.backBtn::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: "";
  border-radius: 50%;
  display: block;
  width: 20em;
  height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.5s ease-out;
  z-index: -1;
}

.backBtn:hover {
  color: #fff;
  border: 1px solid black;
}

.backBtn:hover::before {
  box-shadow: inset 0 0 0 10em black;
}

.textJustify {
  text-align: justify;
  /* hyphens: auto; */
}

.icon {
  width: 2rem;
  height: 2rem;
  color: #353c4a;
  border: 0.125rem solid #f3f3f3;
  box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.15);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 250ms;
}

.icon:hover,
.icon:focus {
  outline-color: inherit;
}

.twitter:hover,
.twitter:focus {
  color: #00aced;
  box-shadow: 0 0 24px 0 #00aced;
}

.fb:hover,
.fb:focus {
  color: #3b5998;
  box-shadow: 0 0 24px 0 #3b5998;
}

.insta:hover,
.insta:focus {
  color: #e4405f;
  box-shadow: 0 0 24px 0 #e4405f;
}

.youtube:hover,
.youtube:focus {
  color: #cd201f;
  box-shadow: 0 0 24px 0 #cd201f;
}

.linkedin:hover,
.linkedin:focus {
  color: #0e76a8;
  box-shadow: 0 0 24px 0 #0e76a8;
}

.normalLink {
  color: black;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.activeLink {
  color: #ffbc03;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}
