* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-wrap: break-word;
}

p,
ul,
ol,
span {
  font-size: 15px;
}